import { InvoiceDTO } from "../../models/invoice/invoice.repository";
import {
  createInvoice,
  createPericiaInvoices,
  deleteInvoice,
  deletePericiaInvoices,
} from "../../storage/supabase/invoice/invoice.storage";
import { invoiceToInsertFactory } from "../../utils/factories/invoice.factory";

//TODO create postgress triggers to update pericias faturado status on creation and on deletion of invoice
//TODO remove trigger

export const invoiceService = {
  createInvoice: async (invoiceDTO: InvoiceDTO) => {
    const invoiceToInsert = invoiceToInsertFactory(invoiceDTO);
    const res = await createInvoice(invoiceToInsert);
    if (res.error) {
      return { data: null, error: res.error };
    }

    const periciaInvoiceDTO = {
      invoice_id: res.data,
      costumer_id: invoiceDTO.costumerId,
      pericias: invoiceDTO.pericias,
    };

    const res2 = await createPericiaInvoices(periciaInvoiceDTO);
    if (res2.error) {
      console.log("error creating pericia invoices", res2.error);

      const { error: error2 } = await deletePericiaInvoices(res.data);
      error2 && console.log("error deleting pericia invoices", error2);

      const { error: error3 } = await deleteInvoice(res.data);
      error3 && console.log("error deleting invoice", error3);

      return { data: null, error: res2.error };
    }

    return { data: res.data, error: null };
  },

  deleteInvoice: async (invoiceId: string) => {
    const { error } = await deletePericiaInvoices(invoiceId);
    if (error) {
      console.log("error deleting pericia invoices", error);
      return { error };
    }

    const { error: error2 } = await deleteInvoice(invoiceId);
    if (error2) {
      console.log("error deleting invoice", error2);
      return { error: error2 };
    }

    return { error: null };
  },
};
