import { CAR_PARTS_ARRAY, SMASH_WORKING_HOURS } from "../../../shared/constants/car-parts.constants";
import { currencyMask, formatIntegerToCurrency } from "../../../utils/helpers/numbers";
import { Car, CreateNewCar } from "../../car/model/car";
import { Costumer, CreateNewCostumer } from "../../costumer/model/costumer";

export interface PericiaInvoiceItem extends Pericia {
  repairRecords: {
    id: string;
    user: {
      id: string;
      display_name: string;
    };
  }[];
}

export function CreateNewPericiaInvoiceItem(): PericiaInvoiceItem {
  return {
    id: "",
    car: CreateNewCar(),
    costumer: CreateNewCostumer(),
    pricePerHour: 70,
    date: new Date(),
    carParts: CreateCarParts(),
    insuranceHours: 0,
    totalHours: 0,
    totalPrice: 0,
    costumerPrice: 0,
    unmountPrice: 0,
    addtionalCost: 0,
    addtionalCostDescription: "",
    shouldUnmount: false,
    finished: false,
    done: false,
    billed: false,
    isPriceCalculated: false,
    isEditable: false,
    priceVersion: 2,
    cashPayedAmount: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    repairRecords: []
  };
}

export interface Pericia {
  id: string;
  car: Car;
  costumer: Costumer;
  pricePerHour: number;
  date: Date;
  carParts: CarPart[];
  insuranceHours: number;
  totalHours: number;
  totalPrice: number;
  costumerPrice: number;
  unmountPrice: number;
  addtionalCost: number;
  addtionalCostDescription: string;
  shouldUnmount: boolean;
  finished: boolean;
  done: boolean;
  billed: boolean;
  isPriceCalculated: boolean;
  isEditable: boolean;
  priceVersion: number;
  cashPayedAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CarPart {
  name: string;
  note: CarPartNote;
  price: number;
  smash: number;
  isAluminum: boolean;
  shouldGlue: boolean;
  smallSmash: number;
  shouldPaint: boolean;
  shouldReplace: boolean;
  smashWorkingHours: number;
  useMaxNoteDetails: boolean;
  smallSmashWorkingHours: number;
  workingHours: number;
  isAddtionalRepair: boolean;
  customHours: number;
  customHoursDescription: string;
}

export interface CarPartNote {
  smashes: string;
  details: string;
}

export function CreateNewPericia(): Pericia {
  return {
    id: "",
    car: CreateNewCar(),
    costumer: CreateNewCostumer(),
    pricePerHour: 70,
    date: new Date(),
    carParts: CreateCarParts(),
    insuranceHours: 0,
    totalHours: 0,
    totalPrice: 0,
    costumerPrice: 0,
    unmountPrice: 0,
    addtionalCost: 0,
    addtionalCostDescription: "",
    shouldUnmount: false,
    finished: false,
    done: false,
    billed: false,
    isPriceCalculated: false,
    isEditable: false,
    priceVersion: 2,
    cashPayedAmount: 0,
    createdAt: new Date(),
    updatedAt: new Date()
  };
}

export function CreateNewCarPart(override?: Partial<CarPart>): CarPart {
  return {
    name: "Cofano",
    shouldGlue: false,
    shouldPaint: false,
    shouldReplace: false,
    isAluminum: false,
    smallSmash: 0,
    smallSmashWorkingHours: 0,
    smash: 0,
    smashWorkingHours: 0,
    useMaxNoteDetails: false,
    price: 0,
    workingHours: 0,
    isAddtionalRepair: false,
    note: {
      details: "",
      smashes: ""
    },
    customHours: 0,
    customHoursDescription: "",
    ...override
  };
}

function CreateCarParts(): CarPart[] {
  return CAR_PARTS_ARRAY.map((carPart) => {
    return {
      name: carPart.value,
      price: 0,
      smash: 0,
      smallSmash: 0,
      smashWorkingHours: 0,
      smallSmashWorkingHours: 0,
      workingHours: 0,
      isAluminum: false,
      shouldGlue: false,
      shouldPaint: false,
      shouldReplace: false,
      useMaxNoteDetails: false,
      isAddtionalRepair: false,
      customHours: 0,
      customHoursDescription: "",
      note: {
        smashes: "",
        details: ""
      }
    };
  });
}

export function PericiaCarPartsNames(): string[] {
  return CAR_PARTS_ARRAY.map((carPart) => carPart.value);
}

export function setupPericiaProperties(pericia: Pericia): Pericia {
  const { carParts, ...rest } = pericia;
  const updatedCarParts = carParts.map((carPart) => {
    return setupCarPartProperties(carPart, rest.pricePerHour, rest.priceVersion);
  });

  return { ...rest, carParts: updatedCarParts };
}

export function setPericiaPricePerHour(pericia: Pericia, pricePerHour: number): Pericia {
  let updatedPericia = { ...pericia, pricePerHour };

  //TODO this code is duplicated. create a function
  updatedPericia.carParts = updatedPericia.carParts.map((carPart) => {
    return { ...carPart, price: (carPart.workingHours * pricePerHour) / 10 };
  });

  updatedPericia.totalPrice = getPericiaTotalPrice(updatedPericia);

  return updatedPericia;
}

export function setCarPartPrice(carPart: CarPart, pricePerHour: number): CarPart {
  return { ...carPart, price: (carPart.workingHours * pricePerHour) / 10 };
}

/**
 * Sets the working hours for a car part based on the given price version.
 * If the car part has a custom hours value, it will be used instead.
 * @param carPart - The car part to set the working hours for.
 * @param priceVersion - The price version to determine the working hours calculation.
 * @returns The car part with the updated working hours.
 */
export function setCarPartWorkingHours(carPart: CarPart, priceVersion: number): CarPart {
  //if carPart as customHours set it should use it
  if (carPart.customHours > 0) {
    return { ...carPart, workingHours: carPart.customHours };
  }

  let totalHours = carPart.smallSmashWorkingHours + carPart.smashWorkingHours;

  switch (priceVersion) {
    case 1:
      if (carPart.shouldPaint) {
        totalHours = totalHours * 0.75;
      }

      if (carPart.isAluminum) {
        totalHours = totalHours * 1.25;
      }

      if (carPart.shouldGlue) {
        totalHours = totalHours * 1.25;
      }

      break;
    case 2:
      let totalHoursv2 = 0;

      if (carPart.shouldPaint) {
        totalHoursv2 = totalHoursv2 + (totalHours / 100) * -25;
      }

      if (carPart.isAluminum) {
        totalHoursv2 = totalHoursv2 + (totalHours / 100) * 25;
      }

      if (carPart.shouldGlue) {
        totalHoursv2 = totalHoursv2 + (totalHours / 100) * 25;
      }

      totalHours = totalHours + totalHoursv2;

      break;
    case 3:
      let totalHoursv3 = totalHours;

      if (carPart.shouldPaint) {
        totalHoursv3 = totalHoursv3 + (totalHoursv3 / 100) * -40;
      }

      if (carPart.isAluminum) {
        totalHoursv3 = totalHoursv3 + (totalHoursv3 / 100) * 25;
      }

      if (carPart.shouldGlue) {
        totalHoursv3 = totalHoursv3 + (totalHoursv3 / 100) * 25;
      }

      totalHours = totalHoursv3;
      break;

    default:
      let totalHoursvDefault = 0;

      if (carPart.shouldPaint) {
        totalHoursvDefault = totalHoursvDefault + (totalHours / 100) * -25;
      }

      if (carPart.isAluminum) {
        totalHoursvDefault = totalHoursvDefault + (totalHours / 100) * 25;
      }

      if (carPart.shouldGlue) {
        totalHoursvDefault = totalHoursvDefault + (totalHours / 100) * 25;
      }

      totalHours = totalHours + totalHoursvDefault;
      break;
  }

  // if (priceVersion === 1) {
  //   if (carPart.shouldPaint) {
  //     totalHours = totalHours * 0.75;
  //   }

  //   if (carPart.isAluminum) {
  //     totalHours = totalHours * 1.25;
  //   }

  //   if (carPart.shouldGlue) {
  //     totalHours = totalHours * 1.25;
  //   }
  // } else {
  //   let totalHoursv2 = 0;

  //   if (carPart.shouldPaint) {
  //     totalHoursv2 = totalHoursv2 + (totalHours / 100) * -25;
  //   }

  //   if (carPart.isAluminum) {
  //     totalHoursv2 = totalHoursv2 + (totalHours / 100) * 25;
  //   }

  //   if (carPart.shouldGlue) {
  //     totalHoursv2 = totalHoursv2 + (totalHours / 100) * 25;
  //   }

  //   totalHours = totalHours + totalHoursv2;
  // }

  carPart.workingHours = +totalHours.toFixed(2);
  return { ...carPart, workingHours: totalHours };
}

export function updatePericiaCarPart(pericia: Pericia, carPart: CarPart): Pericia {
  let updatedPericia = updateCarPart(pericia, carPart);
  updatedPericia = setPericiaTotalHours(updatedPericia);
  updatedPericia = setPericiaTotalPrice(updatedPericia);
  return updatedPericia;
}

function updateCarPart(pericia: Pericia, updatedCarPart: CarPart): Pericia {
  return {
    ...pericia,
    carParts: pericia.carParts.map((carPart) => {
      if (carPart.name === updatedCarPart.name) {
        return setupCarPartProperties(updatedCarPart, pericia.pricePerHour, pericia.priceVersion);
      }
      return carPart;
    })
  };
}

export function setPericiaTotalHours(pericia: Pericia): Pericia {
  return {
    ...pericia,
    totalHours: getPericiaTotalHours(pericia)
  };
}

export function setPericiaTotalPrice(pericia: Pericia): Pericia {
  return {
    ...pericia,
    totalPrice: getPericiaTotalPrice(pericia)
  };
}

export function setPericiaShouldUnmount(pericia: Pericia, shouldUnmount: boolean): Pericia {
  if (!shouldUnmount) {
    return {
      ...pericia,
      shouldUnmount,
      unmountPrice: 0
    };
  }

  return {
    ...pericia,
    shouldUnmount
  };
}

export function setPericiaUnmountPrice(pericia: Pericia, price: number): Pericia {
  return {
    ...pericia,
    unmountPrice: price
  };
}

export function setPericiaIsPriceCalculated(pericia: Pericia, isPriceCalculated: boolean): Pericia {
  return {
    ...pericia,
    isPriceCalculated
  };
}

export function setPericiaAddtionalCost(pericia: Pericia, cost: number): Pericia {
  return {
    ...pericia,
    addtionalCost: cost
  };
}

export function setPericiaAddtionalCostDescription(pericia: Pericia, description: string): Pericia {
  return {
    ...pericia,
    addtionalCostDescription: description
  };
}

export function setPericiaIsEditable(pericia: Pericia, isEditable: boolean) {
  return {
    ...pericia,
    isEDitable: isEditable
  };
}

export function setPericiaIsDone(pericia: Pericia, done: boolean): Pericia {
  return {
    ...pericia,
    done
  };
}

export function setPericiaIsBilled(pericia: Pericia, billed: boolean): Pericia {
  return {
    ...pericia,
    billed
  };
}

export function setpericiaDate(pericia: Pericia, date: Date): Pericia {
  return {
    ...pericia,
    date
  };
}

export function setPericiaCar(pericia: Pericia, car: Car): Pericia {
  return {
    ...pericia,
    car
  };
}

export function setPericiaCostumer(pericia: Pericia, costumer: Costumer): Pericia {
  let updatedPericia = { ...pericia, costumer };
  updatedPericia.priceVersion = costumer.priceVersion;

  updatedPericia = setupPericiaProperties(updatedPericia);
  updatedPericia.totalPrice = getPericiaTotalPrice(updatedPericia);
  return updatedPericia;
}

export function setPericiaInsuranceHours(pericia: Pericia, insuranceHours: number): Pericia {
  return {
    ...pericia,
    insuranceHours
  };
}

export function setPericiaCostumerPrice(pericia: Pericia, costumerPrice: number): Pericia {
  return {
    ...pericia,
    costumerPrice
  };
}

export function getPericiaTotalPrice(pericia: Pericia): number {
  let totalHours = pericia.carParts.reduce((acc, carPart) => {
    return acc + carPart.workingHours;
  }, 0);

  return (totalHours * pericia.pricePerHour) / 10;
}

export function getPericiaTotalHours(pericia: Pericia): number {
  return pericia.carParts.reduce((acc, carPart) => {
    return acc + carPart.workingHours;
  }, 0);
}

//methods to update car parts
export function setSmallSmashWorkingHours(carPart: CarPart): CarPart {
  if (carPart.customHours > 0) {
    return {
      ...carPart,
      smallSmash: 0,
      smallSmashWorkingHours: carPart.customHours,
      smash: 0,
      smashWorkingHours: 0
    };
  }

  if (carPart.smallSmash >= 610) {
    return {
      ...carPart,
      smallSmashWorkingHours: SMASH_WORKING_HOURS[610].smallSmash
    };
  }

  return {
    ...carPart,
    smallSmashWorkingHours: SMASH_WORKING_HOURS[carPart.smallSmash as keyof typeof SMASH_WORKING_HOURS].smallSmash
  };
}

export function setSmashWorkingHours(carPart: CarPart): CarPart {
  if (carPart.customHours > 0) {
    return {
      ...carPart,
      smallSmash: 0,
      smallSmashWorkingHours: carPart.customHours,
      smash: 0,
      smashWorkingHours: 0
    };
  }

  if (carPart.smash >= 610) {
    let smashWorkingHours = SMASH_WORKING_HOURS[610].smash;
    return {
      ...carPart,
      smashWorkingHours,
      workingHours: smashWorkingHours + carPart.smallSmashWorkingHours
    };
  }

  let smashWorkingHours = SMASH_WORKING_HOURS[carPart.smash as keyof typeof SMASH_WORKING_HOURS].smash;
  return {
    ...carPart,
    smashWorkingHours,
    workingHours: smashWorkingHours + carPart.smallSmashWorkingHours
  };
}

export function setUseMaxNoteDetails(carPart: CarPart): CarPart {
  let useMaxNoteDetails = carPart.useMaxNoteDetails ? true : false;

  if (useMaxNoteDetails) {
    let smallSmashWorkingHours = SMASH_WORKING_HOURS[610].smallSmash;
    return {
      ...carPart,
      smallSmash: 610,
      useMaxNoteDetails,
      smallSmashWorkingHours,
      workingHours: smallSmashWorkingHours + carPart.smashWorkingHours
    };
  }

  return { ...carPart, useMaxNoteDetails };
}

export function setNoteDetails(carPart: CarPart): CarPart {
  if (carPart.customHours > 0) {
    carPart.note = {
      smashes: `${carPart.customHours / 10} ore`,
      details: `${carPart.customHoursDescription.slice(0, 4).toUpperCase()}`
    };

    return { ...carPart };
  }

  let smashNotes = "";
  let smallSmashNotes = "";
  let paintNotes = "";
  let isAluminumNotes = "";
  let shouldReplaceNotes = "";
  let shouldGlueNotes = "";
  let isAddtionalRepairNotes = "";

  if (carPart.smallSmash >= 610 && carPart.useMaxNoteDetails) {
    smashNotes = "MASSIMO";
  } else {
    smallSmashNotes = carPart.smallSmash ? String(carPart.smallSmash) : "";
    smashNotes = carPart.smash ? `${String(carPart.smash)}>` : "";
  }

  if (carPart.shouldPaint) {
    paintNotes = "V";
  }

  if (carPart.isAluminum) {
    isAluminumNotes = "AL";
  }

  if (carPart.shouldReplace) {
    shouldReplaceNotes = "SOST";
  }

  if (carPart.shouldGlue) {
    shouldGlueNotes = "C";
  }

  if (carPart.isAddtionalRepair) {
    isAddtionalRepairNotes = "X";
  }

  carPart.note = {
    smashes: formatSmashNotes(smallSmashNotes, smashNotes),
    details: formatDetailsNotes(paintNotes, isAluminumNotes, shouldReplaceNotes, shouldGlueNotes, isAddtionalRepairNotes)
  };

  return { ...carPart };
}

function formatSmashNotes(first: string, second: string) {
  if (!first && !second) {
    return "";
  }

  if (first && second) {
    return `${first} ${second}`.trim();
  }

  return first || second;
}

function formatDetailsNotes(first: string, second: string, third: string, fourth: string, fifth: string) {
  if (!first && !second && !third && !fourth && !fifth) {
    return "";
  }

  const notes = [first, second, third, fourth, fifth].reduce((acc, note) => {
    if (!note) {
      return acc;
    }

    return `${acc} ${note}`;
  }, "");

  return notes.trim();
}

export function setupCarPartProperties(carPart: CarPart, pricePerHour: number, priceVersion: number): CarPart {
  return setCarPartPrice(
    setCarPartWorkingHours(setNoteDetails(setUseMaxNoteDetails(setSmashWorkingHours(setSmallSmashWorkingHours(carPart)))), priceVersion),
    pricePerHour
  );
}

//price methods

export function getPericiaCustomPrice(pericia: Pericia): number {
  if (!pericia.insuranceHours || pericia.insuranceHours === 0) {
    return getPericiaPrice(pericia);
  }

  const price = pericia.insuranceHours * pericia.pricePerHour;
  return pericia.unmountPrice + price + pericia.addtionalCost;
}

export function getPericiaPrice(pericia: Pericia): number {
  if (pericia.costumerPrice > 0) {
    return pericia.costumerPrice;
  }

  const price = pericia.totalPrice;
  return pericia.unmountPrice + price + pericia.addtionalCost;
}

export function getPericiasCustomPrice(pericias: Pericia[]): number {
  return pericias.reduce((acc, pericia) => {
    return acc + getPericiaCustomPrice(pericia);
  }, 0);
}

export function getPericiaCustomPriceCurrencyFormat(pericia: Pericia): string {
  return currencyMask(formatIntegerToCurrency(getPericiaCustomPrice(pericia)));
}
