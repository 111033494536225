import { Navigate, Outlet } from "react-router-dom";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import supabase from "../../shared/database/supabase";

interface ProptectedRouteProps {
  redirectPath?: string;
  children?: React.ReactNode;
}

const ALLOWED_ROLES = [USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.USER_PRIVILEGED, USER_ROLES.LIMITED_ADMIN];

const ProtectedRouteAdmin: React.FC<ProptectedRouteProps> = ({ children, redirectPath = "/auth/sign-in" }) => {
  const user = supabase.auth.user() as UserWithRole;
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  user.isAdmin = user.user_metadata?.role === "admin";
  user.name = user.user_metadata?.name;
  user.permission = user.user_metadata?.role || "user";

  const isAllowed = ALLOWED_ROLES.includes(user.permission);

  if (!user.name) {
    return <Navigate to="/auth/sign-up?operation=finish" />;
  }

  if (!isAllowed) {
    return <Navigate to="/mechanics/pericias/list" />;
  }

  return children ? <>children</> : <Outlet />;
};

export default ProtectedRouteAdmin;
