import supabase from "../../../shared/database/supabase";
import { RepairRecordDTO } from "../../../shared/database/dto";

export async function getRepairRecordsByUserId(id: string) {
  try {
    const { data, error } = await supabase
      .from<RepairRecordDTO>("pericia_repair_records")
      .select(
        `id, 
        pericia:${SELECT_PERICIA_QUERY},
        technician_payment:${SELECT_TECHNICIAN_PAYMENT_QUERY},
        user:users(id), 
        createdAt:created_at, deleted, deletedAt:deleted_at, done, finishedAt:finished_at, payed, payedAt:payed_at, payedValue:payed_value, updatedAt:updated_at`
      )
      .eq("id_user", id);

    if (error) throw error;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const SELECT_COSTUMER_QUERY = `costumers(id, name, phone, phone2, email, address,fullAddress:full_address, language, createdAt:created_at, updatedAt:updated_at)`;

const SELECT_PERICIA_QUERY = `pericias(id, date, costumer:${SELECT_COSTUMER_QUERY}, car:cars(id, brand,model, color, plate, chassisNumber:chassis_number, insuranceName:insurance_name), pricePerHour:price_per_working_hour, shouldUnmount: unmount, unmountPrice:unmount_price,
        cofano, parafango_ad, parafango_as, parafango_pd, parafango_ps, piantone_d, piantone_s, porta_ad, porta_as, porta_pd, porta_ps, sportello_i, sportello_s, tetto, 
        insuranceHours:insurance_hours, totalHours:total_hours, totalPrice:total_price, costumerPrice:costumer_price, done, billed, finished, isEditable:editable,
        isPriceCalculated:price_calculated,priceVersion:price_version,
        addtionalCost:addtional_cost, addtionalCostDescription:addtional_cost_description, createdAt:created_at, updatedAt:updated_at)
`;

const SELECT_TECHNICIAN_PAYMENT_QUERY = `technician_payments(id, user:users(id),pericia:${SELECT_PERICIA_QUERY}, amount, createdAt:created_at, updatedAt:updated_at, deleted, deletedAt:deleted_at)`;
