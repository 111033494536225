import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Invoice } from "../../../../entities/invoice/model/invoice";
import { useNavigate } from "react-router-dom";
import { currencyMask, formatIntegerToCurrency } from "../../../../utils/helpers/numbers";

interface InvoiceListItemComponentProps {
  invoice: Invoice;
}

const InvoiceListItemComponent = ({ invoice }: InvoiceListItemComponentProps) => {
  const navigate = useNavigate();

  return (
    <ListItem key={invoice.id} divider>
      <ListItemButton
        onClick={() => navigate(`/invoices/${invoice.id}`)}
        role={undefined}
        dense
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          textAlign: "center"
        }}
      >
        <ListItemText id={invoice.invoiceCustomCount.toString()} primary={`${invoice.invoiceCustomCount}`} sx={{ flex: "1" }} />
        <ListItemText id={invoice.costumer.name} primary={`${invoice.costumer.name}`} sx={{ flex: "1" }} />
        <ListItemText id={"invoice-createdat-date"} primary={invoice.createdAt.toLocaleDateString("pt-BR")} sx={{ flex: "1" }} />
        <ListItemText id={"invoice-total-price"} primary={currencyMask(formatIntegerToCurrency(invoice.totalPrice))} sx={{ flex: "1" }} />
        <ListItemText id={"invoice-payed-status"} primary={invoice.payed ? "Recebido" : "A receber"} sx={{ flex: "1" }} />
      </ListItemButton>
    </ListItem>
  );
};

export default InvoiceListItemComponent;
