import { IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import PaidIcon from "@mui/icons-material/Paid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";
import { USER_ROLES } from "../../../contexts/user/user.context";

interface ListComponentProps {
  users: User[];
}

const getRoleLabel = (id: string, role: string) => {
  if (role === USER_ROLES.ADMIN) return "Administrador";
  if (role === USER_ROLES.USER_PRIVILEGED) return "Técnico perito";
  if (role === USER_ROLES.SALESMAN) return "Vendedor";
  if (role === USER_ROLES.LIMITED_ADMIN) return "Gestão";
  if (role === USER_ROLES.USER) return "Técnico";
  return "Técnico";
};

const UsersList: React.FC<ListComponentProps> = ({ users }) => {
  return (
    <PaperCard title="">
      {sortUsers(users).map((user) => {
        let { id, role: userRole } = user;
        let displayName = getName(user);

        const role = getRoleLabel(displayName, userRole);
        return (
          <ListItem key={id} divider>
            <ListItemButton
              role={undefined}
              dense
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignText: "left",
                textAlign: "left"
              }}
            >
              <ListItemText id={`${id}-name`} primary={`${displayName}`} sx={{ flex: "1" }} />
              <ListItemText id={`${id}-permissions`} primary={`${role}`} sx={{ flex: "1" }} />
              <IconButton color="primary">
                <a href={`repair-records/${id}`} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
                  <CarRepairIcon />
                </a>
              </IconButton>
              <IconButton color="success">
                <a href={`${id}/balance`} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
                  <PaidIcon />
                </a>
              </IconButton>
              <IconButton>
                <a href={`${id}/update`} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
                  <ManageAccountsIcon />
                </a>
              </IconButton>
            </ListItemButton>
          </ListItem>
        );
      })}
    </PaperCard>
  );
};

function getName({ name, last_name, id, display_name }: User) {
  if (display_name) {
    return display_name;
  }

  return name && last_name ? `${name} ${last_name}` : id;
}

interface User {
  id: string;
  name: string;
  last_name: string;
  permissions: string[];
  display_name: string;
  role: string;
}

function sortUsers(users: User[]) {
  users.sort((a, b) => {
    const userARole = a.role || "user";
    const userBRole = b.role || "user";
    const userADisplayName = a.display_name || a.name || "user";
    const userBDisplayName = b.display_name || b.name || "user";

    if (userARole === userBRole) {
      return userADisplayName.localeCompare(userBDisplayName);
    } else {
      return userARole.localeCompare(userBRole);
    }
  });

  return users;
}

export default UsersList;
